body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto; /* Allow vertical scrolling when necessary */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use min-height to ensure the entire page content fits */
}

.header {
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem 0;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background 0.3s;
}

.nav-link:hover {
  background: #444;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 10px;
  max-width: 80%;
  margin: 0 auto;
  text-align: center; /* Center text horizontally */
}

.text-container h1,
.text-container p,
.text-container li {
  color: #ffffff; /* Set text color to white */
  max-width: 100%;
}

.text-container ul {
  list-style: none;
  padding: 0;
}

.text-container li {
  font-size: 1.1rem;
  margin: 1rem 0;
}

.about-background,
.contact-background,
.home-background,
.services-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding: 2rem;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling */
  text-align: center; /* Center text horizontally */
}

.home-background {
  background-image: url('images/earth.png');
}

.about-background {
  background-image: url('images/about.png');
}

.services-background {
  background-image: url('images/desk.png');
}

.contact-background {
  background-image: url('images/contact.png');
}

.home h1,
.home p {
  color: #ffffff; /* Set text color to white */
}
